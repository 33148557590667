<template>
  <div class="visuals column full-width justify-center items-center">
    <div class="visuals-project-wrapper mt-xl">
      <Projects projectFilter="visual" />
    </div>
  </div>
</template>

<script>
import Projects from "@/components/Projects";

export default {
  name: 'Visuals',
  components: {
    Projects
  },
  setup() {

    //Computed

    //Methods

    return {
    }
  }
}
</script>

<style lang="scss">
/* write SCSS here */
.visuals-project-wrapper {
  width: 70%;
}
.visuals {
  height: auto;
  z-index: 1;
  position: relative;
  top: 0;
  width: 100%;
  display: block;
}
</style>