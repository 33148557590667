<template>
  <div class="cart" :class="{'active' : cartVisible}">
    <div class="cart-content">
      <div class="cart-form">
        <div class="cart-header mt-md">
          <div class="column items-start">
            <h3 class="mb-sm mt-none">Review Your Order:</h3>
            <p style="margin: 0" class="soft-text">({{ customerCart.length }}) {{ customerCart.length > 1 || !customerCart.length ? "Items" : "Item" }}</p>
          </div>
          <div class="column items-start text-right">
            <div class="cursor-pointer" :class="{ 'mb-sm' : cartNotEmpty }"><a @click="goToMerch">Go to Merch</a></div>
            <div class="error cursor-pointer full-width" @click="clearCart" v-if="cartNotEmpty">Clear</div>
          </div>
        </div>
        <hr />
        <div class="cart-products">
          <div v-for="product in customerCart" :key="product.id">
            <Product :product="product" :isCart="true" />
            <hr />
          </div>
        </div>
        <div class="cart-actions row full-width justify-end cursor-pointer" v-if="cartNotEmpty">
          <div @click="continueToCheckout">Continue</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { computed, ref, inject } from 'vue';
import Product from '@/components/Product';

export default {
  name: 'Cart',
  setup() {
    const store = useStore();
    const router = useRouter();
    const cartVisible = inject('cartVisible');
    let loading = ref(false);

    //Methods
    const goToMerch = () => {
      router.push({path: '/merch'})
      closeCart();
    }
    const closeCart = () => {
      cartVisible.value = false;
    }
    const clearCart = () => {
      store.dispatch('clearCart');
    }
    const continueToCheckout = async () => {
      loading.value = true;
      await store.dispatch('goToCheckout', customerCart.value);
      if(checkoutUrl.value){
        window.location = checkoutUrl.value;
      }
      loading.value = false;
    }

    //Computed
    const customerCart = computed(() => store.state.customerCart);
    const checkoutUrl = computed(() => store.state.checkoutUrl);
    const cartNotEmpty = computed(() => { return customerCart.value.length > 0 });

    return {
      loading,
      customerCart,
      cartNotEmpty,
      cartVisible,
      //Methods
      goToMerch,
      closeCart,
      clearCart,
      continueToCheckout
    }
  },
  components: {
    Product
  }
}
</script>

<style lang="scss">
/* write SCSS here */
.cart {
  overflow: hidden;
  position: fixed;
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50px;
  background: rgba(0,0,0,.9);
  z-index: 11;
  transition: all ease-in-out .5s;
  &.active {
    overflow: auto;
    height: calc(100vh - 100px);
    transition: all ease-in-out .5s;
  }
}
.cart-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.cart-content {
  padding: 10px 25px;
}
</style>