
let formatPrice = (dollarAmount) => {
  if(dollarAmount){
    return '$' + (dollarAmount / 100).toFixed(2);
  }
}

const formatters = {
  formatPrice
}

export { formatters };
