<template>
  <div class="home" id="home">
    <div ref="meetTheTeamRef" class="home-meet-the-team">
      <div class="column justify-center items-center hover-style" @click="goTo('/about')">Meet the team</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, inject, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  name: 'Home',
  setup() {
    const router = useRouter();
    let meetTheTeamRef = ref('meetTheTeamRef');

    //Injections
    const setupTransitions = inject('setupTransitions');

    //Methods
    const goTo = (route) => {
      router.push({path: route});
    }

    //Lifecycle Hooks
    onMounted(() => {
      setupTransitions();
      setTimeout(() => {
        meetTheTeamRef.value.style.display = "flex";
      }, 1000);
    })

    return {
      //Data
      meetTheTeamRef,

      //Injections
      setupTransitions,

      //Methods
      goTo
    }
  }
}
</script>

<style lang="scss">
/* write SCSS here */
.home {
  overflow: hidden;
  z-index: 1;
  position: relative;
  top: 0;
  width: 100%;
  display: block;
  height: calc(100vh - 100px);
  .home-content {
    overflow: hidden;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-meet-the-team {
    height: calc(100vh - 100px);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      color: $foam;
      font-size: 4em;
      z-index: 3;
      font-family: 'TNanti';
      animation: fadeDown 1s linear;
    }
  }
}
</style>
