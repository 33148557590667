<template>
  <img id="spinning_heads" src="/images/spinning_heads2.gif" />
</template>
<script>
export default {
  setup() {
    return {

    }
  }
}
</script>

<style lang="scss">
#spinning_heads {
  width: auto;
  height: 100%;
  position: relative;
}
</style>