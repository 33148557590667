<template>
  <div id="footer-container">
    <div class="nav-bar row items-center">
      <HeadLoader />
      <div class="footer-legals">
        <span class="fine-print soft-text">Southern Ocean Productions LLC</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import HeadLoader from './HeadLoader.vue';
export default {
  name: "Footer",
  setup() {
    let subMenuOpen = ref(false);

    function toggleSubMenu(){
      subMenuOpen.value = !subMenuOpen.value;
    }

    function addListeners() {
      //CLose the sub menu when clicking outside of it
      // window.addEventListener('click', function (e) {
      //   if((e.target.id != "subMenu" || e.target.parentElement.id != "subMenu") && subMenuOpen.value == true){
      //     toggleSubMenu();
      //   }
      // })
    }

    onMounted(function() {
      addListeners();
    })

    return {
      subMenuOpen,
      toggleSubMenu
    }
  },
  components: {
    HeadLoader
  },
}
</script>

<style lang="scss">
@import "@/styles/variables";

#footer-container {
  width: 100%;
  position: fixed;
  margin: auto 0 0;
  z-index: 12;
  background: black;
  bottom: 0;
  .nav-bar {
    padding: 0;
    width: 97%;
    display: flex;
  }
  .sun-img {
    width: auto;
    height: 40px;
    cursor: pointer;
  }
}
.footer-terms {
  align-self: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  span {
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}
.footer-legals {
  position: absolute;
  right: 0;
}
.footer-socials {
  width: 25px;
  a {
    cursor: pointer;
  }
}
</style>