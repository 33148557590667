import axios from 'axios';
import { createStore } from 'vuex';
// import mockProjects from "@/data/projects.json";

const store = createStore ({
  state: {
    products: [],
    selectedProduct: null,
    customerCart: [],
    checkoutUrl: null,
    hasMoreProducts: false,
    toastQueue: [],
    isMobile: false,
    projects: [],
    uniqueProductIdCounter: 0,
    hasAdminAccess: false,
    selectedContact: { name: "Southern Ocean Productions", emailKey: "29912be6-d72d-4e52-a848-ece21addabec" }
  },
  mutations: {
    setSelectedContact(state, contact){
      state.selectedContact = contact;
    },
    setAddProductToCart(state, product) {
      state.customerCart.push(product);
    },
    setRemoveProductFromCart(state, product) {
      let productIndex = state.customerCart.findIndex(p => p.id == product.id);
      state.customerCart.splice(productIndex, 1);
    },
    setClearCart(state) {
      state.customerCart = [];
    },
    setProducts(state, products){
      products.forEach(p => {
        //Only use active products
        if(p.active){
          let variantTag = p.metadata?.variant_tag;
          //Check if product is variant of base product (i.e. "blue" shirt, "white" shirt)
          if(variantTag){
            let baseProduct = state.products.find(x => x.metadata?.variant_tag == variantTag || x.name == variantTag);
            //Check for base product of variant (i.e shirt)
            if(baseProduct){
              //If this variant is not already in the base product
              if(!baseProduct.variants.find(v => v.id == p.id)){
                //If the variant product is going to be used as our main add images and description
                if(p.metadata?.main){
                  baseProduct.description = p.metadata?.main_description;
                  p.images?.forEach(i => baseProduct.images.unshift(i));
                }
                baseProduct.variants.push(p);
              }
            } else {
              //Base product not found, so we create one and add the variant
              let product = {
                id: state.uniqueProductIdCounter,
                name: variantTag,
                images: [],
                price: p.price,
                description: "",
                variants: [p]
              };
              p.images?.forEach(i => product.images.push(i));
              state.products.push(product);
              state.uniqueProductIdCounter++;
            }
          } else {
            //If product doesn't have variant and is a one off
            let productDoesntExist = !state.products.find(x => x.id == p.id);
            if(productDoesntExist)
              state.products.push(p)
          }
        }
      });
    },
    setCheckoutUrl(state, url) {
      state.checkoutUrl = url;
    },
    setHasMoreProducts(state, hasMore) {
      state.hasMoreProducts = hasMore;
    },
    setNewToast(state, options){
      state.toastQueue.push(options);
    },
    setRemoveToast(state, id) {
      let currentMessageIndex = state.toastQueue.findIndex(t => t.id == id);
      if(currentMessageIndex >= 0)
        state.toastQueue.splice(currentMessageIndex, 1);
    },
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setProjects(state, value) {
      state.projects = value;
    },
    setSaveProject(state, value) {
      let foundProjectIndex = state.projects?.findIndex(a => a.sop_project_id == value.sop_project_id);
      if(foundProjectIndex >= 0){
        state.projects[foundProjectIndex] = value;
      } else {
        state.projects.push(value);
      }
    },
    setDeleteProject(state, value) {
      let foundProjectIndex = state.projects?.findIndex(a => a.sop_project_id == value);
      if(foundProjectIndex >= 0){
        state.projects.splice(foundProjectIndex, 1);
      }
    },
    setUpdatedCartProduct(state, value) {
      const oldCartProductIndex = state.customerCart.findIndex(ci => ci.id == value.id);
      if(oldCartProductIndex >= 0){
        state.customerCart[oldCartProductIndex] = value;
      }
    },
    setHasAdminAccess(state, value) {
      state.hasAdminAccess = value;
    }
  },
  actions: {
    addToastMessage({commit}, options){
      commit('setNewToast', options)
    },
    removeToastMessage({commit}, id){
      commit('setRemoveToast', id);
    },
    clearCart({commit}){
      commit('setClearCart');
    },
    addProductToCart({commit}, product) {
      commit('setAddProductToCart', product);
    },
    removeProductFromCart({commit}, productId) {
      commit('setRemoveProductFromCart', productId);
    },
    updateCartProduct({commit}, product){
      commit('setUpdatedCartProduct', product);
    },
    async getProducts({commit}, params) {
      let payload = {params: {}};

      if(params?.id)
        payload.params.id = params?.id;
      if(params?.starting_after)
        payload.params.starting_after = params?.starting_after;
      if(params?.starting_after_price)
        payload.params.starting_after_price = params?.starting_after_price;

      let response = await axios.get('/products', payload);
      if(response.data){
        commit('setHasMoreProducts', response?.data?.hasMore);
        if(!params?.id){
          commit('setProducts', response?.data?.results);
        } else {
          return response?.data?.results[0];
        }
      }
    },
    async goToCheckout({commit}, cartItems) {
      if(cartItems?.length > 0){
        let payload = {params: cartItems}
        let response = await axios.post('/checkout', null, payload);

        if(response?.data?.url){
          commit('setCheckoutUrl', response?.data?.url);
        }
      }
    },
    async getProjects({commit}) {
      let response = await axios.post('/getProjects', null, {params: { org: 'production' } });
      if(response.data && response.status == 200){
        commit('setProjects', response.data);
      }
      //Mock results
      // commit('setProjects', mockProjects?.results);
    },
    async saveProject({commit}, project) {
      //add our org
      if(!project.org)
        project.org = 'production';

      let payload = {params: { project: project }};
      let response = await axios.post('/saveProject', null, payload);
      if(response?.data?.error == false && response?.data?.project){
        commit('setSaveProject', response?.data?.project);
      }
    },
    async deleteProject({commit}, projectId) {
      let payload = {params: { projectId: projectId }};
      let response = await axios.post('/deleteProject', null, payload);
      if(response?.data?.error == false){
        commit('setDeleteProject', projectId);
        return true;
      } else {
        return false;
      }
    },
    async submitPassword({commit}, password) {
      let payload = {params: { password: password }};
      let response = await axios.post('/adminAccess', null, payload);
      if(response.data && response.status == 200){
        if(response.data.adminAccessGranted){
          commit('setHasAdminAccess', response.data.adminAccessGranted);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async getSecureS3Url() {
      let response = await axios.get('/getSecureS3Url', null);
      if(response?.data?.url && response?.data?.error == false){
        return response.data.url;
      }
    },
    async deleteS3Media({commit}, mediaId) {
      let payload = {params: {mediaId: mediaId}};
      let response = await axios.get('/deleteS3Media', payload);
      if(response?.data && response?.data?.error == false){
        return response.data.deleted;
      }
    }
  }
});

export default store;