<template>
  <div class="so-projects column full-width">
    <template v-if="filteredProjects?.length > 0">
      <Project :project="project" v-for="project in filteredProjects" :key="project.id" />
    </template>
    <div class="row full-width" v-else>
      Nothing here.
    </div>
  </div>
</template>

<script>
import { computed, toRef } from "vue";
import { useStore } from "vuex";
import Project from "@/components/Project";
export default {
  name: 'Projects',
  components: {
    Project
  },
  props: {
    projectFilter: {
      type: String,
      require: false
    }
  },
  setup(props) {
    const store = useStore();
    let projectFilter = toRef(props, 'projectFilter');

    //Computed
    const visibleProjects = computed(() => {
      let visibleProjects = [];
      const now = new Date();

      visibleProjects = store.state.projects;

      //Filter out expired and hidden projects
      visibleProjects = store.state.projects?.filter(p => {
        const projectExpiration = new Date(p.expiration_date);
        const isExpired = projectExpiration < now;
        return isExpired == false && p?.hidden == "false";
      });

      return visibleProjects;
    });
    const filteredProjects = computed(() => {
      //Grab all the projects under the filter that is specified
      if(projectFilter.value)
        return visibleProjects.value?.filter(p => p.type?.toLowerCase() == projectFilter.value?.toLowerCase());
      else
        return visibleProjects.value;
    });

    return {
      filteredProjects
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';
.showcase-projects {
  .so-project:nth-child(2n+2) {
    flex-direction: row-reverse;
    .project-details {
      padding: 0 10% 0 0;
    }
  }
}
</style>
