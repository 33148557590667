<template>
  <div id="dropdown">
    <div @click="toggleSubMenu" style="height: 27px; width: 27px; border-radius: 50%;" :class="{ 'pulse' : !subMenuOpen }">
      <slot></slot>
    </div>
    <div id="subMenu" :class="{ 'active' : subMenuOpen }">
      <ul id="subMenuList">
        <li class="subMenuListItem"><router-link @click="subMenuOpen = false; cartVisible = false;" to="/">Home</router-link></li>
        <li class="subMenuListItem"><router-link @click="subMenuOpen = false; cartVisible = false;" to="/shows">Shows</router-link></li>
        <li class="subMenuListItem"><router-link @click="subMenuOpen = false; cartVisible = false;" to="/merch">Merch</router-link></li>
        <li class="subMenuListItem"><router-link @click="subMenuOpen = false; cartVisible = false;" to="/music">Music</router-link></li>
        <li class="subMenuListItem"><router-link @click="subMenuOpen = false; cartVisible = false;" to="/visuals">Visuals</router-link></li>
        <li class="subMenuListItem"><router-link @click="subMenuOpen = false; cartVisible = false;" to="/about">About</router-link></li>
        <li class="subMenuListItem"><router-link @click="subMenuOpen = false; cartVisible = false;" to="/contact">Contact</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
export default {
  name: "Dropdown",
  props: {
    position: {
      type: String,
      require: false
    }
  },
  setup() {
    let subMenuOpen = ref(false);
    const cartVisible = inject("cartVisible");

    function toggleSubMenu(){
      subMenuOpen.value = !subMenuOpen.value;
    }

    function addListeners() {
      const dropdown = document.querySelector("#dropdown");
      const subMenu = document.querySelector("#subMenu");
      const subMenuList = document.querySelector("#subMenuList");

      dropdown.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent clicks inside the modal from closing it
      });
      subMenu.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent clicks inside the modal from closing it
      });
      subMenuList.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent clicks inside the modal from closing it
      });

      window.addEventListener('click', () => {
        subMenuOpen.value = false;
      })
    }

    onMounted(function() {
      addListeners();
    })

    return {
      subMenuOpen,
      cartVisible,
      toggleSubMenu
    }
  }
}
</script>

<style lang="scss">
#dropdown {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
#subMenu {
  position: absolute;
  background: #000000b5;
  text-align: center;
  width: 255px;
  z-index: 2;
  border-top: 0;
  top: 38px;
  height: 0px;
  overflow: hidden;
  transition: all ease-in-out .5s;
  &.active {
    height: 245px;
    transition: all ease-in-out .5s;
  }
  ul {
    list-style: none;
    text-align: left;
    padding: 0;
    width: 70%;
    display: inline-block;
    text-align: center;
  }
  li {
    padding: 5px 0;
  }
}
</style>