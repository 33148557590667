<template>
  <div id="success-page" class="row mt-xl full-width justify-center text-center">
    <div class="success-wrapper">
      <h2 class="paymentSuccess">
        Thank you for your purchase!
      </h2>
      <div class="mb-md">
        ~ your business means a lot to us. please <a href="/contact">contact us</a> if you have any questions or concerns. ~
      </div>
      <div>
        You should receive an email with a receipt for your order shortly.
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PaymentSuccess',
  setup() {

    //Methods

    //Computed

    //Lifecycle Hooks


    return {
    }
  },
}
</script>

<style lang="scss">
#success-page {
  position: fixed;
  height: calc(100vh - 100px);
  .success-wrapper {
    width: 90%;
    text-align: center;
  }
}
</style>