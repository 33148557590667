<template>
  <div class="product-page justify-center">
    <div class="product-content">
      <template v-if="!loading && product">
        <div class="product-back">
          <div class="cursor-pointer"><router-link to="/merch">Back</router-link></div>
        </div>
        <img class="product-image" :src="productImage" @click="viewProduct" @load="imgLoading = false" v-show="!imgLoading" />
        <div class="product-load-wrapper" v-show="imgLoading">
          <HeadLoader/>
        </div>
        <div class="product-details">
          <h3 class="mt-none mb-sm">{{product.name}}</h3>
          <div class="plain-text soft-text">{{product.description}}</div>
          <div class="full-width mt-md">{{formatters.formatPrice(productPrice)}}</div>
          <form v-on:submit="(e) => {addProductToCart(e);}">
            <div class="row full-width mt-md">
              <select class="variant-selector mr-md" placeholder="color" v-model="colorVariant" v-if="colorOptions.length > 0" :required="colorOptions.length > 0">
                <option value="">Color</option>
                <option :value="option" v-for="option in colorOptions" :key="option">{{ upperCase(option) }}</option>
              </select>
              <select class="variant-selector" placeholder="size" v-model="sizeVariant" v-if="sizeOptions.length > 0" :required="colorOptions.length > 0">
                <option value="">Size</option>
                <option :value="option" v-for="option in sizeOptions" :key="option">{{ upperCase(option) }}</option>
              </select>
            </div>
            <div class="product-quantity row mt-lg">
              <div class="hover-style" @click="subtractFromQuantity" :class="{ 'disabled' : quantityCounter == 0}">-</div>
              <div class="mx-md">{{ quantityCounter }}</div>
              <div class="hover-style" @click="addToQuantity">+</div>
            </div>
            <button class="add-to-cart hover-style mt-md" type="submit">Add To Cart</button>
          </form>
        </div>
      </template>
      <div class="product-not-found" v-else-if="!loading && !product">
        <h3>Product not found :/</h3>
      </div>
      <template v-else>
        Loading...
      </template>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import { formatters } from "@/helpers/formatters";
import { useToast } from "@/composables/useToast";
import HeadLoader from "@/components/HeadLoader";
export default {
  name: 'ProductPage',
  components: {
    HeadLoader
  },
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const toast = useToast();
    const store = useStore();
    let product = ref(null);
    let loading = ref(true);
    let imgLoading = ref(true);
    let colorVariant = ref("");
    let sizeVariant = ref("");
    let quantityCounter = ref(1);

    //Computed
    const allProducts = computed(() => {return store.state.products});
    const productImage = computed(() => {
      if(productVariants.value && colorVariant.value){
        const variantWithSelectedColor = productVariants.value.find(v => v.metadata?.color == colorVariant.value);
        return variantWithSelectedColor.images[0];
      } else {
        return product.value.images[0];
      }
    });
    const productPrice = computed(() => {return product.value?.price || product.value?.variants[0].price});
    const customerCart = computed(() => {return store.state.customerCart});
    const productVariants = computed(() => {return product.value?.variants});
    const colorOptions = computed(() => {
      let options = [];
      productVariants.value?.forEach(v => {
        const color = v.metadata?.color;
        const colorAlreadyAdded = options.find(o => o == color);
        if(color && !colorAlreadyAdded){
          options.push(color);
        }
      });
      return options;
    });
    const sizeOptions = computed(() => {
      let options = [];
      if(!colorOptions.value || colorVariant.value){
        productVariants.value?.forEach(v => {
          const size = v.metadata?.size;
          const color = v.metadata?.color;
          const sizeAlreadyAdded = options.find(o => o == size);
          if(size && !sizeAlreadyAdded){
            if(colorVariant.value){
              if(color == colorVariant.value){
                options.push(size);
              }
            }
          }
        });
      }
      return options;
    });

    //Methods
    const upperCase = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const addToQuantity = () => {
      if(quantityCounter.value < 50)
        quantityCounter.value++;
    }
    const subtractFromQuantity = () => {
      if(quantityCounter.value > 0)
        quantityCounter.value--;
    }
    const getProduct = async () => {
      product.value = await store.dispatch('getProducts', {id: props.productId});
      loading.value = false;
    }
    const addProductToCart = async (event) => {
      let productToAdd;

      event?.preventDefault();

      //Get product from variants list if applicable
      if(productVariants.value){
        productToAdd = productVariants.value.find(v =>
          (colorVariant.value && v.metadata?.color == colorVariant.value || !colorVariant.value) &&
          (sizeVariant.value && v.metadata?.size == sizeVariant.value || !sizeVariant.value)
        );
      } else {
        productToAdd = product.value;
      }

      //Check if product is already in the cart
      const existingCartProduct = customerCart.value?.find(p => p.id == productToAdd.id);

      //Add product to cart if it doesn't already exist
      if(!existingCartProduct){
        if(quantityCounter.value > 0){
          productToAdd.quantity = quantityCounter.value;
          await store.dispatch('addProductToCart', productToAdd);
          toast.newMessage({ type: 'positive', message: `Added to cart`, timeout: 2500 });
        }
      } else {
        //Update the existing product in the cart
        productToAdd.quantity = (quantityCounter.value + existingCartProduct.quantity);
        await store.dispatch('updateCartProduct', productToAdd);
        toast.newMessage({ type: 'positive', message: `Added to cart`, timeout: 2500 });
      }
    }

    //Watchers
    watch(colorVariant, () => {
      if(sizeOptions.value?.length > 0){
        sizeVariant.value = "";
      }
    })

    //Lifecycle Hooks
    onMounted(async () => {
      if(props.productId?.includes("prod_")){
        //Singular Product
        await getProduct();
      } else {
        //Product Variants Available
        if(!allProducts.value?.length){
          await store.dispatch('getProducts');
        }
        product.value = allProducts.value.find(p => p.id == props.productId);
        loading.value = false;
      }
    });

    return {
      //Data
      formatters,
      quantityCounter,
      colorVariant,
      sizeVariant,
      //Computed
      allProducts,
      product,
      productImage,
      productPrice,
      loading,
      imgLoading,
      productVariants,
      colorOptions,
      sizeOptions,
      //Methods
      upperCase,
      addToQuantity,
      subtractFromQuantity,
      getProduct,
      addProductToCart,
    }
  }
}
</script>

<style lang="scss">
/* write SCSS here */
.product-page {
  z-index: 1;
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 154px);
  align-items: center;
}
.product-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 92%;
  width: 100%;
  .product-load-wrapper {
    min-width: 30vw;
  }
}
.variant-selector {
  color: #fff;
  background: none;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 5px;
  option {
    color: black;
  }
}
.product-back {
  padding-right: 1rem;
  width: 215px;
  display: flex;
  justify-content: flex-end;
}
.product-image {
  height: 100%;
  width: auto;
  &.product-image-loading {
    background: red;
  }
}
.product-details {
  padding-left: 1rem;
  width: 215px;
}
.add-to-cart {
  font-weight: bold;
  text-transform: uppercase;
  background: none;
  border: 1px solid white;
  border-radius: 3px;
  height: 35px;
  color: white;
  padding: 0 20px;
  margin-top: 20px;
}
</style>
