<template>
  <div class="music column full-width justify-center items-center">
    <div class="music-project-wrapper mt-xl">
      <Projects projectFilter="music" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Projects from "@/components/Projects";

export default {
  name: 'Music',
  components: {
    Projects
  },
  setup() {

    //Computed

    //Methods

    return {
    }
  }
}
</script>

<style lang="scss">
/* write SCSS here */
.music {
  z-index: 1;
  position: relative;
}
.music-project-wrapper {
  width: 70%;
}
</style>