<template>
  <div id="header-container">
    <div class="nav-bar">
      <div class="row full-width items-center justify-between" v-if="!isMobile">
        <div class="header-drawer-wrapper row" ref="headerWrapper">
          <img ref="headerLogo" @click="goTo('/')" class="southern-ocean-logo" src="/images/so_shape_w.png" />
          <div ref="headerSocialsDrawer" class="header-socials-drawer row items-center">
            <img class="cursor-pointer header-icon" src="/images/instagram_icon_white.png" alt="southern-ocean-instagram-icon" @click="common.windowOpen('https://www.instagram.com/southernoceanproductions/')"/>
          </div>
        </div>
        <div class="full-width row justify-center items-center self-center">
          <div class="nav-links">
            <a @click="goTo('/shows')" class="mr-lg">Shows</a>
            <a @click="goTo('/merch')" class="mr-lg">Merch</a>
            <a @click="goTo('/music')" class="mr-lg">Music</a>
            <a @click="goTo('/visuals')" class="mr-lg">Visuals</a>
            <a @click="goTo('/about')" class="mr-lg">About</a>
            <a @click="goTo('/contact')">Contact</a>
          </div>
        </div>
        <div class="bar-cart row justify-center items-center" @click="toggleCart">
          <img class="cart-img" src="/images/cart_icon.png" />
          <div class="cart-counter">{{ customerCart > 0 ? customerCart : '' }}</div>
        </div>
      </div>
      <template v-else>
        <div class="header-socials row items-center">
          <img class="cursor-pointer header-icon" src="/images/instagram_icon_white.png" alt="southern-ocean-instagram-icon" @click="common.windowOpen('https://www.instagram.com/southernoceanproductions/')"/>
        </div>
        <Dropdown>
          <img class="southern-ocean-logo-mobile" src="/images/so_shape_w.png" />
        </Dropdown>
        <div class="bar-cart row justify-center items-center" @click="toggleCart">
          <img class="cart-img" src="/images/cart_icon.png" />
          <div class="cart-counter">{{ customerCart > 0 ? customerCart : '' }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { common } from "@/helpers/common";
import Dropdown from './Dropdown.vue';
// import HeadLoader from './HeadLoader.vue';
export default {
  name: "Header",
  setup() {
    const store = useStore();
    const router = useRouter();
    const cartVisible = inject('cartVisible');
    let subMenuOpen = ref(false);
    let headerSocialsDrawer = ref('headerSocialsDrawer');
    let headerLogo = ref('headerLogo');
    let headerWrapper = ref('headerWrapper');

    //Methods
    function goTo(route) {
      router.push({path: route});
      closeCart();
    }
    function toggleSubMenu() {
      subMenuOpen.value = !subMenuOpen.value;
    }
    function toggleCart() {
      cartVisible.value = !cartVisible.value;
    }
    function closeCart() {
      cartVisible.value = false;
    }

    //Computed Properties
    const customerCart = computed(() => {
      let itemsInCart = 0;
      if(store.state.customerCart.length > 0){
        store.state.customerCart.forEach(item => {
          itemsInCart += item.quantity;
        })
      }
      return itemsInCart;
    });
    const isMobile = computed(() => store.state.isMobile);

    //Lifecycle Hooks
    onMounted(() => {
      if(!isMobile.value){
        setTimeout(() => {
          headerSocialsDrawer.value?.classList.add('open-drawer')
        }, 2000);
        setTimeout(() => {
          headerSocialsDrawer.value?.classList.remove('open-drawer')
        }, 4000);
        headerWrapper.value?.addEventListener('mouseenter', () => {
          if(!headerSocialsDrawer.value?.classList.contains('open-drawer'))
            headerSocialsDrawer.value?.classList.add('open-drawer');
        })
        headerWrapper.value?.addEventListener('mouseleave', () => {
          headerSocialsDrawer.value?.classList.remove('open-drawer');
        })
      }
    })

    return {
      common,
      subMenuOpen,
      customerCart,
      headerLogo,
      headerWrapper,
      headerSocialsDrawer,
      isMobile,
      cartVisible,
      toggleSubMenu,
      toggleCart,
      closeCart,
      goTo,
    }
  },
  components: {
    Dropdown,
    // HeadLoader
  },
}
</script>

<style lang="scss">
@import "@/styles/variables";

#header-container {
  width: 100%;
  position: fixed;
  z-index: 12;
  background: black;
  .nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 97%;
    height: 50px;
    margin: 0px auto;
    position: relative;
    z-index: 2;
  }
  .header-socials {
    .header-icon {
      height: auto;
      width: 55%;
      z-index: 3;
    }
  }
  .header-drawer-wrapper {
    position: absolute;
  }
  .southern-ocean-logo {
    max-width: 30px;
    position: relative;
    cursor: pointer;
    animation: fadeDown 1s;
    z-index: 3;
  }
  .header-socials-drawer {
    position: relative;
    opacity: 0;
    left: 0;
    transition: all ease-in-out .5s;
    z-index: 2;
    &.open-drawer {
      opacity: 1;
      left: 17px !important;
      transition: all ease-in-out .5s;
    }
    .header-icon {
      height: auto;
      width: 50%;
    }
  }
  .southern-ocean-logo-mobile {
    max-width: 30px;
    animation: fadeIn 1s;
    position: relative;
    top: -1px;
    right: 2px;
  }
}
.bar-info {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.bar-cart {
  position: relative;
  cursor: pointer;
  position: absolute;
  right: 5px;
  img {
    width: 28px;
  }
}
.cart-counter {
  text-align: center;
  font-size: 9px;
  position: absolute;
  top: 10px;
  width: 100%;
  left: 1px;
  &:hover {
    color: $primary
  }
}
.nav-links {
  display: flex;
  flex-direction: row;
  color: $secondary-text;
  font-size: 12px;
  font-family: 'body-font';
  cursor: pointer;
  text-transform: uppercase;
  animation: fadeIn 2s;
  div:hover {
    color: $primary;
  }
}
</style>