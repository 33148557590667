<template>
  <Header></Header>
  <div id="content">
    <VideoWrapper parentId="content">
      <router-view />
    </VideoWrapper>
  </div>
  <Footer></Footer>
  <Toast v-for="t in toastQueue" :key="t.id" :options="t"></Toast>
  <Cart />
</template>

<script>
import { onMounted, computed, ref, provide } from 'vue';
import VideoWrapper from "@/components/VideoWrapper";
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Toast from '@/components/Toast';
import Cart from '@/views/Cart';
import { useToast } from "@/composables/useToast";
import { useStore } from "vuex";

export default {
  name: "SouthernOcean",
  components: {
    VideoWrapper,
    Header,
    Footer,
    Toast,
    Cart
  },
  setup(){
    const store = useStore();
    const toast = useToast();
    let cartVisible = ref(false);
    let hiddenElements = ref(null);

    //Computed
    const toastQueue = computed(() => store.state.toastQueue);
    const isMobile = computed(() => store.state.isMobile);
    const cart = computed(() => store.state.customerCart);

    //Methods
    const getProjects = async () => {
      await store.dispatch('getProjects');
    }

    const checkIsMobile = (event) => {
      const e = event?.currentTarget || window;
      if(e?.innerWidth <= 699 && !isMobile.value){
        store.commit('setIsMobile', true);
      } else if(e?.innerWidth > 699 && isMobile.value){
        store.commit('setIsMobile', false);
      }
    };
    const setupTransitions = () => {
      hiddenElements.value = document.querySelectorAll('.hidden');
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            entry.target.classList.remove('hidden');
          } else {
            entry.target.classList.add('hidden');
          }
        })
      });
      hiddenElements.value?.forEach((el) => observer.observe(el));
    };
    const setupEventListeners = () => {
      window.addEventListener('beforeunload', function(event) {
        if(cart.value?.length > 0)
          event.returnValue = null;
      });
      window.addEventListener('resize', (e) => {
        checkIsMobile(e);
      });
    };

    //Hooks
    onMounted(() => {
      checkIsMobile();
      getProjects();
      setupTransitions();
      setupEventListeners();
    });

    provide('cartVisible', cartVisible);
    provide('setupTransitions', setupTransitions);

    return {
      toast,
      toastQueue,
      isMobile,
      cartVisible,
      checkIsMobile,
      setupTransitions,
      setupEventListeners,
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

#app {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding: 0px;
  width: 100%;
  height: 100%;
  padding: 0;
}
#content {
  padding: 0;
  top: 50px;
  height: calc(100vh - 100px);
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  .to-top-button {
    position: fixed;
    bottom: 10vh;
    right: 5%;
    font-size: 32px;
    cursor: pointer;
    border: 2px wavy;
    padding: 8px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $default;
    z-index: 123;
  }
}
</style>
