<template>
  <div class="so-project row items-center full-width" :class="[ project.title || project.description || project.button_text ? 'justify-between' : 'justify-center' ]">
    <img :src="project.media_url" @click="common.windowOpen(project.project_link)" class="cursor-pointer" v-if="project.media_type == 'image' && !isYoutubeLink" />
    <video :src="project.media_url" @click="common.windowOpen(project.project_link)" class="cursor-pointer" v-else-if="project.media_type == 'video' && !isYoutubeLink" autoplay loop>
      <source :src="project.media_url" />
    </video>
    <iframe class="project-youtube-media" :src="`https://www.youtube.com/embed/${isYoutubeLink ? extractYouTubeVideoID(project.project_link) : ''}`" v-else-if="isYoutubeLink" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <div class="project-details" v-if="project.title || project.description || project.button_text">
      <div v-if="project.title" class="project-title text-h2">{{ project.title }}</div>
      <div v-if="project.description" class="row full-width soft-text">{{ project.description }}</div>
      <button
        v-if="project.button_text && project.project_link"
        @click="project.outside_link ? common.windowOpen(project.project_link) : router.push({path: project.project_link})"
        class="project-button">{{ project.button_text }}</button>
    </div>
  </div>
</template>

<script>
// import Loader from "@/components/Loader";
import { useRouter } from "vue-router";
import { common } from "@/helpers/common";
import { computed, toRef, ref } from "vue";
export default {
  name: 'Project',
  components: {
    // Loader
  },
  props: {
    project: {
      require: true,
      typeof: Object
    }
  },
  setup(props) {
    const router = useRouter();
    const project = toRef(props, 'project');

    //Computed
    const isYoutubeLink = computed(() => {
      const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([a-zA-Z0-9_-]{11})(?:[?&][^ ]*)?$/;

      console.log("is youtube link: ", youtubeRegex.test(project.value?.project_link))

      if(project.value?.project_link){
        return youtubeRegex.test(project.value?.project_link);
      } else {
        return false;
      }
    });

    //Methods
    function extractYouTubeVideoID(url) {
      const regex = /(?:youtube\.com\/(?:.*[?&]v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&]|$)/;
      const match = url.match(regex);
      return match ? match[1] : null;
    }

    return {
      common,
      router,
      //Computed
      isYoutubeLink,
      //Methods
      extractYouTubeVideoID

    }
  },
}
</script>

<style lang="scss">
.so-project {
  margin-bottom: 100px;
  img, video {
    width: 100%;
    max-width: 70%;
  }
}
.project-details {
  margin-top: 15px;
  width: 60%;
  padding: 0 0 0 10%;
}
.project-title {
  margin-bottom: 20px;
}
.project-button {
  padding: 0 20px;
  width: max-content;
  height: 50px;
  background: none;
  border: 1px solid white;
  border-radius: 8px;
  color: white;
  text-transform: uppercase;
  font-size: 17px;
  margin-top: 50px;
  cursor: pointer;
}
.project-youtube-media {
  width: 100%;
  height: 800px;
}
</style>
