<template>
  <div class="merch">
    <div class="merch-modes">
      <div
        v-for="mode in merchModes"
        :key="mode"
        @click="selectMode(mode)"
        class="merch-mode"
        :class="{ 'active-mode' : (selectedMode?.key == mode?.key) }"
      >
        {{mode.label}}
      </div>
    </div>
    <template v-if="filteredProducts?.length != 0 && !productsLoading">
      <div class="merch-products">
        <Product :product="product" v-for="product in filteredProducts" :key="product.id" />
        <div class="column full-width my-lg justify-center items-center">
          <template v-if="hasMoreProducts">
            <span v-if="moreLoading">Loading...</span>
            <span v-else class="cursor-pointer mb-sm" @click="getMoreProducts">Show More</span>
          </template>
          <span v-if="filteredProducts.length > 4" class="cursor-pointer" @click="scrollTop">back to top</span>
        </div>
      </div>
    </template>
    <div class="merch-error" v-else-if="!productsLoading && filteredProducts?.length == 0">
      Nothing here.
    </div>
    <template v-else>
      <div class="row full-width justify-center items-center" style="height: calc(100vh - 154px);">Loading...</div>
    </template>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import Product from '@/components/Product';
export default {
  name: 'Merch',
  setup() {
    const store = useStore();
    let moreLoading = ref(false);
    let productsLoading = ref(true);
    let selectedMode = ref({ label: "All", key: "All" });
    let merchModes = ref([
      { label: "All", key: "All" },
      { label: "Music", key: "music" },
      { label: "Clothing", key: "clothing" },
      { label: "Other", key: "other" }
    ]);

    //Methods
    function scrollTop() {
      var el = document.querySelector('#header-container');
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    function selectMode(mode) {
      if(mode)
        selectedMode.value = mode;
    }
    async function getMoreProducts() {
      moreLoading.value = true;
      let lastProduct = store.state.products[store.state.products.length - 1];
      await store.dispatch('getProducts', {starting_after: lastProduct.id, starting_after_price: lastProduct.default_price});
      moreLoading.value = false;
    }

    //Computed
    const hasMoreProducts = computed(() => store.state.hasMoreProducts)
    const allProducts = computed(() => store.state.products);
    const filteredProducts = computed(() => {
      if(selectedMode.value?.key != "All")
        return [...allProducts.value]?.filter(ap => ap.metadata?.type == selectedMode.value?.key);
      else
        return [...allProducts.value];
    });

    //Lifecycle Hooks
    onMounted(async () => {
      await store.dispatch('getProducts');
      productsLoading.value = false;
    })

    return {
      productsLoading,
      merchModes,
      selectedMode,
      hasMoreProducts,
      allProducts,
      filteredProducts,
      scrollTop,
      selectMode,
      getMoreProducts
    }
  },
  components: {
    Product
  },
}
</script>

<style lang="scss" scoped>
/* write SCSS here */
.merch {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
}
.merch-modes {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px 25px;
  background: #000;
  color: #afafaf;
  transition: color .5s ease-in-out;
  text-transform: uppercase;
  .merch-mode {
    font-size: 11px;
    cursor: pointer;
    &.active-mode {
      color: $primary;
      transition: color ease-in-out .5s;
    }
  }
}
.merch-error {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.merch-title {
  margin-bottom: 20px;
}
.merch-products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 20px;
  height: calc(100% - 74px);
}
</style>