<template>
  <div :class="[isCart ? 'cart-product' : 'merch-product']" @mouseover="showPrice = true" @mouseout="showPrice = false">
    <template v-if="!isCart">
      <img :src="productImage" @click="viewProduct" @load="imgLoading = false" v-show="!imgLoading" />
      <div class="product-load-wrapper" v-show="imgLoading">
        <HeadLoader />
      </div>
      <div class="column full-width mt-md">
        <div class="product-name mb-sm">
          {{ product.name }}
        </div>
        <div>
          {{ formatters.formatPrice(productPrice) }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="product-info">
        <img :src="productImage" @click="viewProduct" />
        <div class="product-name">{{ product.name }}</div>
      </div>
      <div class="product-actions row">
        <div class="product-quantity row mr-md">
          <div class="hover-style" @click="subtractFromQuantity" :class="{ 'disabled' : product.quantity == 0}">-</div>
          <div class="mx-md">{{ product.quantity }}</div>
          <div class="hover-style" @click="addToQuantity">+</div>
        </div>
        <div @click="removeProductFromCart" class="error">X</div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, toRef, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { formatters } from "@/helpers/formatters";
import HeadLoader from "@/components/HeadLoader";
export default {
  name: 'Product',
  components: {
    HeadLoader
  },
  props: {
    product: {
      require: true,
      type: Object,
    },
    isCart: {
      require: false,
      type: Boolean
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    let showPrice = ref(false);
    let product = toRef(props, 'product');
    let imgLoading = ref(true);

    //Methods
    const addToQuantity = async () => {
      product.value.quantity += 1;
      await store.dispatch('updateCartProduct', product.value);
    }
    const subtractFromQuantity = async () => {
      if(product.value.quantity == 1){
        await store.dispatch('removeProductFromCart', product.value);
      } else {
        product.value.quantity -= 1;
        await store.dispatch('updateCartProduct', product.value);
      }
    }
    const removeProductFromCart = async () => {
      if(customerCart.value?.find(p => p.id == product.value.id)){
        await store.dispatch('removeProductFromCart', product.value);
        // alert(`${product.value?.name} has been removed from your cart`);
      }
    }
    const viewProduct = () => {
      if(!product.value?.metadata?.variant_tag){
        router.push({
          name: 'ProductPage',
          params: {id: product.value?.id}
        });
      } else {
        let baseProduct = allProducts.value.find(p => p.name == product.value?.metadata?.variant_tag);
        if(baseProduct) {
          router.push({
            name: 'ProductPage',
            params: {id: baseProduct.id}
          });
        }
      }
    }

    //Computed
    const allProducts = computed(() => {return store.state.products});
    const productImage = computed(() => product.value?.images ? product.value?.images[0] : '');
    const productIsNew = computed(() => product.value?.metadata ? product.value?.metadata.new : false);
    const customerCart = computed(() => {return store.state.customerCart});
    const productPrice = computed(() => {return product.value?.price});

    return {
      showPrice,
      productImage,
      productIsNew,
      productPrice,
      formatters,
      imgLoading,
      addToQuantity,
      subtractFromQuantity,
      removeProductFromCart,
      viewProduct
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';

.product-add {
  align-self: flex-end;
}
.product-price {
  position: absolute;
  top: 20px;
  right: 0;
  text-align: center;
  padding: 3px 10px;
  font-size: 15px;
  border-radius: 30px 0 0 30px;
  width: 70%;
}
.merch-product {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: .2s ease-in-out box-shadow;
  min-height: 273px;
  width: 23%;
  margin: 10px 1%;
  img {
    width: 100%;
    height: auto;
  }
  .slide-enter-active, .slide-leave-active {
    transition: all .2s ease-in;
  }
  .slide-enter, .slide-leave-to /* .slide-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
}
.product-load-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #spinning_heads {
    width: auto;
    height: 19vh !important;
    border-radius: 50%;
  }
}
.cart-product {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  width: 100%;
  .product-info {
    display: flex;
  }
  img {
    position: relative;
    display: block;
    width: 75px;
  }
  .product-name {
    margin-left: 20px;
  }
  .product-actions {
    margin-left: 20px;
    .product-quantity {
      height: 20px;
    }
  }
}
</style>
