<template>
  <div class="about column full-width justify-center items-center">
    <div class="about-header full-width column text-h2 items-center justify-center">
      <div class="column justify-center items-center">
        <div class="about-header-text scroll-fade-down hidden">A production company</div>
        <div class="about-header-text scroll-fade-down hidden">A place for ideas</div>
        <div class="about-header-text scroll-fade-down hidden">A family</div>
      </div>
    </div>
    <div class="about-team full-width column items-center">
      <template v-for="(member, index) in teamMembers" :key="member.name">
        <TeamMember :memberData="member" :customClasses="index % 2 ? 'hidden scroll-fade-right' : 'hidden scroll-fade-left'" />
      </template>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from "vue";
import TeamMember from "@/components/TeamMember";
import team from "@/data/team.json";

export default {
  name: 'About',
  components: {
    TeamMember
  },
  setup() {
    let teamMembers = ref(team);

    //Methods
    const setupTransitions = inject('setupTransitions');

    //Lifecycle Hooks
    onMounted(() => {
      setupTransitions();
    })

    return {
      teamMembers,
      setupTransitions
    }
  }
}
</script>

<style lang="scss">
/* write SCSS here */
.about-header {
  position: relative;
  height: calc(100vh - 100px);
  font-family: 'TNanti';
  .about-header-text {
    font-size: 1.5em;
    margin-bottom: 25px;
    &:nth-child(2) {
      transition-delay: 400ms;
    }
    &:nth-child(3) {
      transition-delay: 800ms;
    }
  }
}
.about {
  height: auto;
  z-index: 1;
  position: relative;
  top: 0;
  width: 100%;
  display: block;
}
.about-scroll {
  height: 40px;
}
.arrow-down {
  height: 8px;
  width: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.about-team {
  padding: 30px 0;
  min-height: 100vh;
}
</style>