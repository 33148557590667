<template>
  <div class="team-member full-width" :class="{'no-img' : !memberData.imgSrc}">
    <img v-if="memberData.imgSrc" class="member-img" :class="customClasses" :src="memberData.imgSrc" :alt="'image of ' + memberData.name" />
    <div class="member-details">
      <div class="member-name row items-center text-h2 uppercase">
        <div class="row" :class="oppositeClass">{{ memberData.name }}</div>
        <div class="member-socials row items-center ml-md">
          <img v-if="memberData.instaHandle" class="member-icon cursor-pointer" :class="oppositeClass" src="/images/instagram_icon_white.png" :alt="memberData.name + ' instagram'" @click="common.windowOpen('https://www.instagram.com/' + memberData.instaHandle)"/>
          <img v-if="memberData.youtubeUrl" class="member-icon cursor-pointer" :class="oppositeClass" src="/images/youtube_icon.png" :alt="memberData.name + ' youtube'" @click="common.windowOpen(memberData.youtubeUrl)"/>
          <img class="member-icon cursor-pointer" :class="oppositeClass" src="/images/mail_icon.png" :alt="'send mail to ' + memberData.name" @click="goToContact(memberData)"/>
        </div>
      </div>
      <div class="member-summary plain-text soft-text" :class="oppositeClass">
        {{ memberData.summary }}
      </div>
      <div class="member-description soft-text" :class="oppositeClass">
        <div v-html="memberData.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, computed } from "vue";
import { useRouter } from 'vue-router'
import { common } from "@/helpers/common";
import { useStore } from "vuex";

export default {
  name: 'TeamMember',
  props: {
    memberData: {
      type: Object,
      required: true
    },
    customClasses: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    let subject = ref(null);
    let message = ref(null);
    let from = ref(null);
    const router = useRouter();

    //Methods
    const goToContact = (contactMember) => {
      store.commit('setSelectedContact', {name: contactMember?.name, emailKey: contactMember?.emailKey});
      router.push({ name: 'Contact' });
    }

    //Computed
    const oppositeClass = computed(() => {
      if(props.customClasses.includes('scroll-fade-right')){
        return 'scroll-fade-left hidden';
      } else {
        return 'scroll-fade-right hidden';
      }
    });

    return {
      //Import
      common,
      //Methods
      goToContact,
      //Data
      subject,
      message,
      from,
      oppositeClass
    }
  }
}
</script>

<style lang="scss">
.team-member {
  margin: 30px 0;
  height: auto;
  width: 80% !important;
  &.no-img {
    height: auto;
    justify-content: flex-start;
    flex-direction: row !important;
    .member-details {
      padding: 0px !important;
    }
  }
}
.member-details {
  width: 100%;
  .member-name {
    word-break: break-word;
  }
  .member-summary {
    transition-delay: 400ms;
    text-align: left;
    font-style: italic;
    margin: 5px 0 10px 0;
  }
  .member-description {
    transition-delay: 400ms;
    font-size: 20px;
    margin: 0 0 15px 0;
    div {
      margin: 10px 0 0 0;
    }
  }
}
.member-icon {
  transition-delay: 600ms;
  width: 25px;
  &:nth-child(2n+2) {
    transition-delay: 800ms;
    margin-left: 10px;
  }
  &:nth-child(3n+3) {
    transition-delay: 1200ms;
    margin-left: 10px;
  }
}
.member-img {
  width: 50%;
  max-width: 500px;
  height: auto;
  max-height: 100%;
  position: relative;
  opacity: 1;
  float: left;
  padding-left: 0;
  padding-right: 30px;
  padding-bottom: 15px;
}
.team-member:nth-child(2n+2) {
  flex-direction: row-reverse;
  .member-img {
    padding-left: 30px;
    padding-bottom: 15px;
    padding-right: 0;
    float: right;
  }
}
</style>