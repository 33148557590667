<template>
  <div id="main-video-wrapper">
    <div id="secondary-video-wrapper">
      <div class="overlay"></div>
      <div id="video-bucket"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "videoWrapper",
  props: {
    parentId: {
      type: String,
      require: true
    }
  },
  setup(props) {
    const store = useStore();
    let videoLoading = ref(true);
    let currentSource = ref('');
    let allVideos = ref(["/media/alex & R&Y TTLR2.mov","/media/alex dance TTLR.mov","/media/alex setting up TLLR.mov","/media/alex TTLR3.mov","/media/brett line out the doorTTLR 6.mov","/media/brett stamp TTLR 5.mov","/media/brett TTLR 3.mov","/media/brett TTLR 4.mov","/media/d kyle & R&Y TTLR3.mov","/media/d preforming TLLR.mov","/media/darrion & kyle TTLR2.mov","/media/darrion & R&Y TTLR3.mov","/media/Darrion Gabe.mov","/media/darrion high ground bts1.mov","/media/darrion TTLR2.mov","/media/darrion TTLR3.mov","/media/darrion TTLR4 .mov","/media/izzy TTLR1.mov","/media/jessie & R&Y TTLR.mov","/media/jessie TTLR1.mov","/media/jessie TTLR2.mov","/media/kyle & alex TTLR.mov","/media/kyle TTLR 5.mov","/media/R&Y izzy setting up TLLR.mov","/media/R&Y TTLR2.mov","/media/R&Y.mov","/media/setting up TTLR 6.mov","/media/setting up TTLR 7.mov","/media/TTLR mic.mov"]);
    let resizeListenerTimeout = ref(null);

    //Computed
    const isMobile = computed(() => store.state.isMobile);

    //Methods
    const setupVideos = () => {
      let currentPageVideoCount = 0;
      let videosLeftToAdd = 0;
      const parentEl = document.getElementById(props.parentId);
      const main = document.getElementById("main");
      const videoBucket = document.getElementById('video-bucket');

      //Clear the video bucket
      videoBucket.innerHTML = '';

      //Check if mobile and get the amount of videos we can fit
      if(window?.innerWidth <= 699){
        currentPageVideoCount = 4;
        videosLeftToAdd = 4;
      } else {
        if(parentEl.clientHeight > parentEl.clientWidth || parentEl.clientWidth < 1560 && parentEl.clientHeight > 800){
          currentPageVideoCount = 3;
          videosLeftToAdd = 3;
        } else {
          currentPageVideoCount = 1;
          videosLeftToAdd = 1;
        }
      }

      //Set the height of our main div to equal the height of the parent element
      if(main){
        main.style.height = parentEl.clientHeight;
      }

      //Add our videos to the video bucket
      while(videosLeftToAdd > 0){
        if(videoBucket){
          //Create new video
          let newVidEl = document.createElement('video');
          let newVidSourceEl = document.createElement('source');
          let newVidCropper = document.createElement('div');
          //Set attrs
          newVidEl.setAttribute('playsinline', true);
          newVidCropper.style.height = `${parentEl.clientHeight / currentPageVideoCount}px`;
          newVidCropper.classList.add('video-cropper');
          newVidEl.id = `video-${videosLeftToAdd}`;
          newVidEl.muted = true;
          newVidSourceEl.src = allVideos.value[Math.floor(Math.random() * (allVideos.value.length - 1))];
          //Append source in video
          newVidEl.appendChild(newVidSourceEl);
          newVidCropper.appendChild(newVidEl);
          //Append to video-bucket
          videoBucket.appendChild(newVidCropper);
          //Setup listeners
          if(newVidEl){
            newVidEl.load();
            newVidEl.play();
            newVidEl.addEventListener('ended', () => {rotateSource(newVidEl, newVidSourceEl);},false);
          }
          //drop the amount of videos to add after adding this video
          videosLeftToAdd--;
          if(videosLeftToAdd){
            videoLoading.value = false;
          }
        }
      }
    }
    const rotateSource = (vidEl, srcEl) => {
      //set video index to currentVideoIndex
      if(vidEl && srcEl){
        srcEl.src = allVideos.value[Math.floor(Math.random() * (allVideos.value.length - 1))];
        vidEl.load();
        vidEl.play();
      }
    }
    const addListeners = () => {
      window.addEventListener('resize', () => {
        videoLoading.value = true;
        if(!resizeListenerTimeout.value){
          resizeListenerTimeout.value = setTimeout(() => {
            setupVideos();
            resizeListenerTimeout.value = null;
          }, 500)
        }
      });
    }

    //Lifecycle Hooks
    onMounted(() => {
      addListeners();
      setupVideos();
    });

    return {
      //Data
      videoLoading,
      currentSource,
      allVideos,

      //Computed
      isMobile,

      //Methods
      setupVideos,
      rotateSource,
      addListeners
    }
  }
}
</script>

<style lang="scss">
#main-video-wrapper {
  width: 100%;
  height: 100%;
}
#secondary-video-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  #video-bucket {
    height: 100%;
    .video-cropper {
      // height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      video {
        position: relative;
        width: 100%;
        // height: max-content;
        height: auto;
        z-index: 1;
      }
    }
  }
  .overlay {
    position: absolute;
    width: 120%;
    height: 100%;
    top: 0;
    left: -10%;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.7);
    // box-shadow: inset 0 0 2em 2.8em #111;
    z-index: 2;
    cursor: pointer;
  }
  video {
    width: 100%;
    height: auto;
    z-index: 1;
  }
}
</style>