<template>
  <div class="contact column full-width justify-center items-center">
    <div class="contact-form-wrapper">
      <div class="row full-width justify-center items-center">
        <div class="contact-form">
          <form method="POST" action="https://api.web3forms.com/submit">
            <input type="hidden" name="access_key" :value="selectedContact.emailKey" />
            <select v-model="selectedContact" name="to" class="form-input to">
              <option :value="{ name: 'Southern Ocean Productions', emailKey: '29912be6-d72d-4e52-a848-ece21addabec'}">Southern Ocean Productions</option>
              <option v-for="contact in allContacts" :value="contact" :key="contact">{{ contact.name }}</option>
            </select>
            <br />
            <input v-model="email" type="email" name="email" placeholder="Email" class="form-input email" required>
            <br />
            <input v-model="name" type="name" name="name" placeholder="Name" class="form-input name" required>
            <br />
            <textarea v-model="message" name="message" placeholder="Message" class="form-message" rows="10" width="100%" required></textarea>
            <br />
            <div class="row full-width justify-end"><button type="submit" class="form-send">Send</button></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import team from "@/data/team.json";

export default {
  name: 'Contact',
  setup() {
    const store = useStore();
    let email = ref(null);
    let message = ref(null);
    let name = ref(null);
    let allContacts = ref(team.map((t) => {return { name: t.name, emailKey: t.emailKey }}));

    //Computed
    let selectedContact = computed(() => {
      return store.state.selectedContact
    });

    //Methods
    const clearForm = () => {
      email.value = null;
      name.value = null;
      message.value = null;
    }

    //Life cycle hooks
    onMounted(() => {
      clearForm();
    });

    return {
      //Data
      email,
      message,
      name,
      allContacts,
      selectedContact,
      //Methods
      clearForm
    }
  }
}
</script>

<style lang="scss">
/* write SCSS here */
.contact {
  height: auto;
  z-index: 1;
  position: relative;
  top: 0;
  width: 100%;
  display: block;
}
.contact-form-wrapper {
  width: 70%;
  border-radius: 3px;
  padding: 50px 0;
}
.contact-form {
  display: inline-block;
  text-align: left;
  width: 55%;
  height: 50%;
  form {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
  }
  .form-send {
    padding: 0 20px;
    height: 50px;
    background: none;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    text-transform: uppercase;
    font-size: 17px;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>